import {Component} from "react";
import {Box, Grid, Typography} from "@mui/material";
import "./Banderin.css";
import images from "../../assets/images";

class Banderin extends Component {
  render() {
    return (
      <Box className={"App-subsection"}>
        <Typography variant={"h4"} className={"App-section-subtitle"}>BANDERIN</Typography>
        <Grid container spacing={5} className={"Banderin"}>
          <Grid item xs={12} xl={6} className={"Banderin-info"}>
            <Typography variant={"body1"}>
              En el banderín del <strong>Rotary Club de Caleta Olivia</strong> se encuentran representados elementos que
              evocan la identidad y la historia de esta ciudad costera en la Patagonia argentina. <br/><br/>
              En la parte superior del banderín, se puede apreciar un fondo de color naranja, que representa
              los <strong>atardeceres ventosos</strong> característicos de la región y que simbolizan la fuerza y la
              perseverancia de su gente. <br/><br/>
              También se puede ver la figura de un <strong>buque petrolero</strong>, que representa la principal
              actividad económica de la región y simboliza la navegación, la exploración y el progreso. <br/><br/>
              En el centro del banderín, se encuentra la imagen de una colonia de <strong>lobos marinos</strong>, animal
              emblemático de la zona y que simboliza la fuerza, la tenacidad y la adaptabilidad, valores que son
              fundamentales en la actividad rotaria. <br/><br/>
              Finalmente, en la parte inferior del banderín, se encuentra la imagen del monumento al <strong>obrero
              petrolero</strong>, un homenaje a la dedicación y el esfuerzo de aquellos trabajadores que han construido
              la ciudad y su historia. <br/><br/>
              En conjunto, estos elementos conforman un banderín que representa la fuerza, la dedicación y el espíritu
              de superación de Caleta Olivia y su gente, valores que son compartidos por el Rotary Club de esta
              ciudad.
            </Typography>
          </Grid>
          <Grid item xs={12} xl={6}>
            <Box className={"Banderin-image"} sx={{display: {xs: 'none', xl: 'flex'}}}>
              <img src={images.banderin} alt={"Banderin"} className={"Banderin-image"}/>
            </Box>
            <Box className={"Banderin-image"} sx={{display: {xs: 'none', sm: 'flex', xl: 'none'}}}>
              <img src={images.banderin} alt={"Banderin"} className={"Banderin-image-medium"}/>
            </Box>
            <Box className={"Banderin-image"} sx={{display: {xs: 'flex', sm: 'none'}}}>
              <img src={images.banderin} alt={"Banderin"} className={"Banderin-image-mobile"}/>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default Banderin;
