import "../App/App.css";
import "./Authorities.css";
import {Card, CardContent, CardMedia, Grid, Box, Typography, Link} from "@mui/material";
import images from "../../assets/images";
import {Component} from "react";
import * as PropTypes from "prop-types";

class Authority extends Component {
  render() {
    let {club, name, contact, image} = this.props;
    return (
      <Card className="Authority-container" sx={{boxShadow: 'none'}}>
        <CardMedia
          component="img"
          alt="presidente"
          className="Authority-image"
          image={image}
        />
        <CardContent>
          <Typography variant={"h3"} className={"Authority-club"}>{club}</Typography>
          <Typography variant={"h3"} className={"Authority-name"}>{name}</Typography>
          <Typography variant={"h3"} className={"Authority-mail"}>{contact}</Typography>
        </CardContent>
      </Card>
    )
  }
}

Authority.propTypes = {
  club: PropTypes.string,
  name: PropTypes.string,
  contact: PropTypes.string,
  image: PropTypes.string
}

class Authorities extends Component {
  render() {
    return (
      <Box className="App-subsection" id="autoridades">
        <Typography variant={"h4"} className={'App-section-subtitle'}>AUTORIDADES</Typography>
        <Typography variant={"h3"} className={'Authorities-period'}>Período 2023/2024</Typography>
        <Grid container spacing={4} className={"Authorities-container"}>
          <Grid item xs={12} sm={6} lg={3}>
            <Authority club={"Rotary"} name={"Jorge Aibar"} image={images.president_rotary}
                       contact={"jorgeaibar@hotmail.com"}/>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Authority club={"Rotaract"} name={"Alicia Villalba"} image={images.president_rotaract}
                       contact={"rotaract.co4921@gmail.com"}/>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Authority club={"Interact"} name={"Joaquina Belén Sandoval"} image={images.president_interact}
                       contact={"joaquina07@hotmail.com"}/>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Authority club={"Rueda Interna"} name={"Claudia Alamo"} image={images.president_rueda}
                       contact={"+54 9 297 508-6482"}/>
          </Grid>
          <Link variant={"h3"} href={"./Presidentes Rotary 1968 al presente.pdf"} target={"_blank"}
                className={"Authorities-past-authorities-link"}>Ver autoridades pasadas</Link>

        </Grid>
      </Box>
    );
  }
}

export default Authorities;
