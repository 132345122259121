import "./Contact.css"
import "../App/App.css"
import {Grid, Box, Typography} from "@mui/material";
import {Component} from "react";

class Contact extends Component {
  render() {
    return (
      <Box className="App-section Contact" id="contacto">
        <Typography variant={"h4"} className={'App-section-title'}>CONTACTO</Typography>
        <Grid container spacing={0} className={"Contact-container"}>
          <Grid item xs={12} md={6}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1456.3003560673485!2d-67.52091107264708!3d-46.44614479060546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbde5df95c886e5a7%3A0xeb7943e5bf0b7b92!2sCasa%20de%20la%20Amistad%20-%20Rotary%20Club%20Caleta%20Olivia!5e0!3m2!1sen!2sar!4v1699754037215!5m2!1sen!2sar"
              width={"100%"} height={"300rem"} title="location" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
          </Grid>
          <Grid item xs={12} md={6} className="Contact-data">
            <Typography variant={"h3"} className={"Contact-subtitle"}>Correo</Typography>
            <Typography variant={"h3"} className={"Contact-info"}>rotaryclubco@gmail.com</Typography>
            <Typography variant={"h3"} className={"Contact-subtitle"}>Reuniones</Typography>
            <Typography variant={"h3"} className={"Contact-info"}>Martes 21 hs</Typography>
            <Typography variant={"h3"} className={"Contact-subtitle"}>Dirección</Typography>
            <Typography variant={"h3"} className={"Contact-info"}>Progreso López 75, Caleta Olivia</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default Contact;
