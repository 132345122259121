import "../App/App.css";
import "./Collaborate.css";
import {Grid, Box, Typography, IconButton, Snackbar} from "@mui/material";
import {Component} from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

class Collaborate extends Component {

  alias = "ROTARY.CLUB.CO";

  state = {
    isCopied: false,
  };

  handleCopyAlias = () => {
    navigator.clipboard.writeText(this.alias);

    this.setState({isCopied: true});

    setTimeout(() => {
      this.setState({isCopied: false});
    }, 2000);
  }

  handleCloseSnackbar = () => {
    this.setState({isCopied: false});
  };

  render() {

    return (
      <Box className="App-section Collaborate" id="colaborar">
        <Typography variant={"h4"} className={"App-section-title"}>COLABORAR</Typography>
        <Grid container spacing={5} className={"Collaborate-container"}>
          <Grid item xs={12} className={"Collaborate-text-area"}>
            <Typography variant={"h3"} className={"Collaborate-phrase"} sx={{display: {xs: 'none', md: 'flex'}}}>¡Crea
              esperanza en el mundo!</Typography>
            <Typography variant={"h3"} className={"Collaborate-phrase-mobile"} sx={{display: {xs: 'flex', md: 'none'}}}>¡Crea
              esperanza en el mundo!</Typography>
            <Typography variant={"body1"}>
              El <strong>Rotary Club</strong> de Caleta Olivia se financia a través de las contribuciones de sus socios
              y de las donaciones de la comunidad. <br/><br/>
              Si desea colaborar con el Club, puede hacerlo mediante transferencia bancaria a nuestra cuenta del Banco
              Nacion Argentina, usando el siguiente alias: <br/><br/>
            </Typography>
            <Box className={"Collaborate-alias-container"}>
              <Typography variant="body1" className={"Collaborate-alias"}>
                {this.alias}
              </Typography>
              <IconButton onClick={this.handleCopyAlias} display>
                <ContentCopyIcon/>
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Snackbar
          open={this.state.isCopied}
          autoHideDuration={2000}
          onClose={this.handleCloseSnackbar}
          message="Copiado!"
        />
      </Box>
    );
  }
}

export default Collaborate;
