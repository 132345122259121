import caleta from "./img/caleta_olivia.jpg";
import rotary_wheel from "./img/rotary_wheel.png";
import rotary_logo_white from "./img/rotary_logo.png";
import rotary_logo_black from "./img/rotary_logo_black.png";
import president_rotary from "./img/president_rotary.jpg";
import president_rotaract from "./img/president_rotaract.jpg";
import president_interact from "./img/president_interact.jpg";
import president_rueda from "./img/president_rueda.jpg";
import banderin from "./img/banderin.png";


const images = {
  caleta: caleta,
  rotary_wheel: rotary_wheel,
  rotary_logo_white: rotary_logo_white,
  rotary_logo_black: rotary_logo_black,
  president_rotary: president_rotary,
  president_rotaract: president_rotaract,
  president_interact: president_interact,
  president_rueda: president_rueda,
  banderin: banderin,
}

export default images;
