import {InstagramEmbed} from 'react-social-media-embed';
import {Grid, Box, Typography} from "@mui/material";
import "./Posts.css"

function Posts() {
  return (
    <Box className="App-section" id="posts">
      <Typography variant={"h4"} className={'App-section-title'}>POSTS</Typography>
      <Grid container spacing={4} className={"Posts-container"}>
        <Grid item xs={12} md={6} xl={3}>
          <InstagramEmbed url="https://www.instagram.com/p/Cy58VFlAow9/" className={"Post"}/>
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <InstagramEmbed url="https://www.instagram.com/p/CvqX8OqMN8f/" className={"Post"}/>
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <InstagramEmbed url="https://www.instagram.com/p/CyoOA1CAKRP/" className={"Post"}/>
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <InstagramEmbed url="https://www.instagram.com/p/CyRaGMwuUab/" className={"Post"}/>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Posts;
