import images from "../../assets/images";
import React, {Component} from "react";
import {AppBar, Box, Toolbar, Button, Typography, Link, IconButton, Menu, MenuItem} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import "./NavigationBar.css";

class NavigationBar extends Component {

  state = {
    menuAnchorEl: null,
  }

  handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({menuAnchorEl: event.currentTarget});
  };

  handleMenuClose = () => {
    this.setState({menuAnchorEl: null})
  }

  render() {
    const {menuAnchorEl} = this.state;

    return (
      <AppBar className={"NavigationBar"} id={"inicio"}>
        <Toolbar className={"NavigationBar-toolbar"} disableGutters={true} sx={{display: {xs: 'none', md: 'flex'}}}>
          <Link href="#inicio" onClick={() => window.scrollTo(0, 0)} sx={{display: {xs: 'none', md: 'flex'}}}>
            <img src={images.rotary_logo_black} height="40rem" alt="rotary-logo"/>
          </Link>
          <Box className={"NavigationBar-button-container"} sx={{display: {xs: 'none', md: 'flex'}}}>
            <Button href="#about" color="inherit" className={"NavigationBar-button"}>
              <Typography variant={"h3"}>ACERCA DE ROTARY</Typography>
            </Button>
            <Button href="#posts" color="inherit" className={"NavigationBar-button"}>
              <Typography variant={"h3"}>POSTS</Typography>
            </Button>
            <Button href="#colaborar" color="secondary" variant={"contained"} className={"NavigationBar-button"}>
              <Typography variant={"h3"}>COLABORAR</Typography>
            </Button>
            <Button href="#contacto" color="inherit" className={"NavigationBar-button"}>
              <Typography variant={"h3"}>CONTACTO</Typography>
            </Button>
          </Box>
        </Toolbar>
        <Toolbar className={"NavigationBar-toolbar-mobile"} disableGutters={true}
                 sx={{display: {xs: 'flex', md: 'none'}}}>
          <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
            <IconButton size="large" color="secondary" onClick={this.handleMenuOpen}>
              <MenuIcon/>
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={this.handleMenuClose}
            >
              <MenuItem onClick={this.handleMenuClose}>
                <Link href={"#about"} style={{textDecoration: 'none', color: 'inherit'}}>
                  <Typography variant={"h3"}>ACERCA DE ROTARY</Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={this.handleMenuClose}>
                <Link href={"#posts"} style={{textDecoration: 'none', color: 'inherit'}}>
                  <Typography variant={"h3"}>POSTS</Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={this.handleMenuClose}>
                <Link href={"#colaborar"} style={{textDecoration: 'none', color: 'inherit'}}>
                  <Typography variant={"h3"}>COLABORAR</Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={this.handleMenuClose}>
                <Link href={"#contacto"} style={{textDecoration: 'none', color: 'inherit'}}>
                  <Typography variant={"h3"}>CONTACTO</Typography>
                </Link>
              </MenuItem>
            </Menu>
          </Box>
          <Link href="#inicio"
                className={"NavigationBar-logo-mobile"}
                onClick={() => window.scrollTo(0, 0)} sx={{display: {xs: 'flex', md: 'none'}}}>
            <img src={images.rotary_logo_black} height="40rem" alt="rotary-logo"/>
          </Link>
        </Toolbar>
      </AppBar>
    );
  }


}

export default NavigationBar;
