import "./App.css";
import * as React from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import {StyledEngineProvider, ThemeProvider} from "@mui/material";
import theme from "../../themeConfig";
import Banner from "../Banner/Banner";
import About from "../About/About";
import Banderin from "../Banderin/Banderin";
import Authorities from "../Authorities/Authorities";
import Collaborate from "../Collaborate/Collaborate";
import Footer from "../Footer/Footer";
import Contact from "../Contact/Contact";
import Posts from "../Posts/Posts";
import Box from "@mui/material/Box";


function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Box className="App">
          <NavigationBar/>
          <Banner/>
          <About/>
          <Banderin/>
          <Authorities/>
          <Posts/>
          <Collaborate/>
          <Contact/>
          <Footer/>
        </Box>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
