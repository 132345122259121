import "../App/App.css"
import "./About.css"
import {Typography, Box} from "@mui/material";
import {Component} from "react";

class About extends Component {
  render() {
    return (
      <Box className="App-section" id="about">
        <Typography variant={"h4"} className={'App-section-title'}>ACERCA DE ROTARY</Typography>
        <Typography variant={"body1"} className={"About-content"}>
          El <strong>Rotary Club Internacional</strong> es una organización sin fines de lucro dedicada a promover la
          amistad y el servicio comunitario a nivel global. Fundado en 1905 en Chicago, este prestigioso club reúne a
          profesionales y líderes de diversas áreas para colaborar en proyectos humanitarios, de desarrollo y educativos
          en sus comunidades locales y en todo el mundo. Rotary se distingue por su lema "Dar de sí antes de pensar en
          sí" y su compromiso con la erradicación de enfermedades, la promoción de la paz y la mejora de la calidad de
          vida de las personas.
        </Typography>
        <Typography variant={"body1"} className={"About-content"}>
          El <strong>Rotary Club Caleta Olivia</strong>, fundado el 30 de diciembre de 1968, se reúne los martes a las
          21:00hs en la Casa de la Amistad. <br/>
          Te invitamos a generar amistad a través del servicio a la comunidad.
        </Typography>
      </Box>
    )
  }
}

export default About;
