import "./Footer.css";
import "../App/App.css";
import images from "../../assets/images";
import {Component} from "react";
import {Box, Link, Typography} from "@mui/material";

class Footer extends Component {
  render() {
    return (
      <Box className="Footer">
        <img
          src={images.rotary_logo_white}
          alt="rotary_logo_white"
          className="Footer-image"
        />
        <Typography variant={"body1"} className={"Footer-text"}>
          Rotary Club Caleta Olivia <br/>
          Progreso López 75, Caleta Olivia <br/>
          rotaryclubco@gmail.com
        </Typography>
        <Typography variant={"body1"} className={"Footer-copyright"}>
          © 2023 Rotary - Distrito 4921. Todos los Derechos Reservados.
        </Typography>
        <Link href={"https://panthera.ar"} variant={"h4"} underline={"none"} className={"Footer-powered-by"}>
          Powered by Panthera
        </Link>
      </Box>
    );
  }
}

export default Footer;
