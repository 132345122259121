import images from "../../assets/images";
import "./Banner.css"
import {Box, Typography} from "@mui/material";
import {Component} from "react";

class Banner extends Component {
  render() {
    return (
      <Box className="Banner">
        <img src={images.caleta} alt="caleta-olivia" className="Banner-background"/>
        <Box className={"Banner-title-container"} sx={{display: {xs: 'none', sm: 'flex'}}}>
          <Box className={"Banner-title"} sx={{display: {xs: 'none', sm: 'block'}}}>
            <Typography variant={"h1"} color={"primary"}>Rotary</Typography>
            <Typography variant={"h1"} color={"primary"}>Club</Typography>
            <Typography variant={"h2"} color={"primary"}>Caleta Olivia, Santa Cruz</Typography>
          </Box>
          <Box className={"Banner-wheel-container"} sx={{display: {xs: 'none', sm: 'flex'}}}>
            <img src={images.rotary_wheel} alt="rotary-wheel" className={"Banner-wheel"}/>
          </Box>
        </Box>
        <Box className={"Banner-title-container-mobile"} sx={{display: {xs: 'flex', sm: 'none'}}}>
          <Box className={"Banner-wheel-container-mobile"} sx={{display: {xs: 'flex', sm: 'none'}}}>
            <img src={images.rotary_wheel} alt="rotary-wheel" className={"Banner-wheel-mobile"}/>
          </Box>
          <Box className={"Banner-title-mobile"} sx={{display: {xs: 'block', sm: 'none'}}}>
            <Typography variant={"h1"} color={"primary"} sx={{fontSize: '13vw'}}>Rotary</Typography>
            <Typography variant={"h1"} color={"primary"} sx={{fontSize: '13vw'}}>Club</Typography>
            <Typography variant={"h2"} color={"primary"} sx={{fontSize: '3.5vw'}}>Caleta Olivia, Santa Cruz</Typography>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Banner;