import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFA",
      contrastText: "#000000",
    },
    secondary: {
      main: "#000000",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#FF0080",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#0CB7F2",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#1014D5",
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    h1: {
      fontFamily: "Arial, sans-serif",
      fontSize: "5.5rem",
      fontWeight: "bold",
      lineHeight: "1",
    },
    h2: {
      fontFamily: "Arial, sans-serif",
      fontSize: "1rem",
    },
    h3: {
      fontFamily: "Archivo, sans-serif",
      fontSize: "1rem",
    },
    h4: {
      fontFamily: "Antonio, sans-serif",
      fontSize: "4.5rem",
      fontWeight: "bold",
    },
    body1: {
      fontFamily: "Archivo, sans-serif",
      fontSize: "1.2rem",
    }
  }
});

export default theme;
